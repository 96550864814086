// Patient-level Scopes
const PATIENT_SCOPES = [
    "launch/patient",
    "patient/Patient.read",
    "patient/Observation.read",
    "patient/Condition.read",
    "patient/Medication.read",
    "patient/MedicationRequest.read",
    "patient/MedicationStatement.read",
    "patient/Procedure.read",
    "patient/AllergyIntolerance.read",
    "patient/DocumentReference.read",
    "patient/CarePlan.read",
    "patient/CareTeam.read",
    "patient/Device.read",
    "patient/Goal.read",
    "patient/Location.read",
    "patient/Organization.read",
    "patient/DiagnosticReport.read",
    "patient/Encounter.read",
    "patient/Immunization.read",
    "patient/Practitioner.read",
    "patient/Provenance.read",
    "patient/PractitionerRole.read",
];
// User-level Scopes
const USER_SCOPES = [
    "launch",
  "user/Medication.read",
  "user/AllergyIntolerance.read",
  "user/CarePlan.read",
  "user/CareTeam.read",
  "user/Condition.read",
  "user/Device.read",
  "user/DiagnosticReport.read",
  "user/DocumentReference.read",
  "user/Encounter.read",
  "user/Goal.read",
  "user/Immunization.read",
  "user/Location.read",
  "user/MedicationRequest.read",
  "user/Observation.read",
  "user/Organization.read",
  "user/Patient.read",
  "user/Practitioner.read",
  "user/Procedure.read",
  "user/Provenance.read",
  "user/PractitionerRole.read",
  "user/ServiceRequest.read",
  "user/RelatedPerson.read",
  "user/QuestionnaireResponse.read",
  "user/Media.read",
  "user/Coverage.read",
  "user/MedicationDispense.read",
  "user/ServiceRequest.read",
  "user/RelatedPerson.read",
  "user/Specimen.read"
];

// System-level Scopes
const SYSTEM_SCOPES = [
    "system/Patient.read",
    "system/Observation.read",
    "system/Condition.read",
    "system/MedicationRequest.read",
    "system/MedicationStatement.read",
    "system/Procedure.read",
    "system/AllergyIntolerance.read",
    "system/CarePlan.read",
    "system/DiagnosticReport.read",
    "system/Encounter.read",
    "system/Immunization.read",
];
// OpenID Scopes
const OPENID_SCOPES = ["openid", "profile", "offline_access","fhirUser"];

// EXPORTS
export const SCOPES = {
    PATIENT: PATIENT_SCOPES,
    USER: USER_SCOPES,
    SYSTEM: SYSTEM_SCOPES,
    OPENID: OPENID_SCOPES,
};
