import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import PatientSurvey from "./pages/PatientSurveys/PatientSurvey";
import AuthFrame from "./frames/AuthFrame";
import Base from "./pages/Base";
import ConnectYourAccounts from "./pages/ConnectYourAccounts/ConnectYourAccounts";
import ThirdPartyAuth from "./pages/ThirdPartyAuthLogin/ThirdPartyAuth";
import OTPValidation from "./pages/OTPValidation/OTPValidation";
import Auth0ProviderWithHistory from "./lib/Auth0Provider/Auth0ProviderWithHistory";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
function App() {
    return (
        <div className="cexAuthApp">
            <BrowserRouter>
                <Routes>
                    <Route element={<PatientSurvey />} path="/survey" />
                    <Route element={<Base />} path="/" />
                    <Route element={<ConnectYourAccounts />} path="/connectyouraccounts" />
                    <Route element={<Auth0ProviderWithHistory><ThirdPartyAuth /></Auth0ProviderWithHistory>} path="/thirdpartyauth" />
                    <Route element={<OTPValidation />} path="/validation" />
                </Routes>
            </BrowserRouter>
            <HashRouter>
                <Routes>
                    <Route element={<AuthFrame login />} path="/login" />
                    <Route element={<AuthFrame register />} path="/register" />
                    <Route element={<AuthFrame forgotPassword />} path="/forgot" />
                    <Route element={<AuthFrame resetPassword />} path="/reset" />
                    <Route element={<AuthFrame twofactorauth />} path="/2fa" />
                    <Route element={<AuthFrame verifyAccount />} path="/newpassword" />
                    <Route element={<AuthFrame accountVerified />} path="/accountVerified" />
                    <Route element={<TermsAndConditions />} path="/terms" />
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
