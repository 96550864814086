import { CancelToken } from "axios";
import { doGet, doPost, doPatch } from "./gateway/carexGateway";

const ThirdPartyAuthData = {
    getThirdPartyAuthData: async () => {
        try {
            const res: any = await doGet("getThirdPartyAuthData", {});
            return Promise.resolve(res.data);
        } catch (error) {
            //eslint-disable-next-line
            console.log("error", error);
            Promise.reject(error);
        }
    },

    //ADD
    addNewThirdPartyAuthData: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload.data };
            const res: any = await doPost("addNewThirdPartyAuthData", {
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (error) {
            //eslint-disable-next-line
            console.log("error", error);
            Promise.reject(error);
        }
    },

    //UPDATE
    updateThirdPartyAuthData: async (payload: any) => {
        try {
            const res: any = await doPatch("updateThirdPartyAuthData", payload);
            return Promise.resolve(res.data);
        } catch (error) {
            //eslint-disable-next-line
            console.log("error", error);
            Promise.reject(error);
        }
    },

    //DELETE
    deleteThirdPartyAuthData: async (payload: any) => {
        try {
            const res: any = await doPatch("deleteThirdPartyAuthData", {
                params: { ":idToken": payload.idToken },
                data: { action: payload.action },
            });
            return Promise.resolve(res.data);
        } catch (error) {
            //eslint-disable-next-line
            console.log("error", error);
            Promise.reject(error);
        }
    },

    //get token
    getTokenFromAuth0: async (payload: any) => {
        try {
            const res: any = await doPost("getTokenFromAuth0", payload);
            return Promise.resolve(res.data);
        } catch (error) {
            //eslint-disable-next-line
            console.log("error", error);
            Promise.reject(error);
        }
    },
};

export default ThirdPartyAuthData;
