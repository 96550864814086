import React, { useEffect, useState } from "react";
import "./ActualTokens.styles.scss";
import closeIcon from "../../../assets/icons/closeModal.svg";
import deleteIcon from "../../../assets/icons/delete.svg";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";

import {
    getPatientThirdPartyAuthDataThunkAction,
    deleteThirdPartyAuthDataThunkAction,
} from "../../../redux/thirdPArtyAuth/thirdPartyAuth.slice";
import { ThirdPartyAuthTokenDataSelector } from "../../../redux/thirdPArtyAuth/thirdPartyAuth.selectors";

interface ActualTokensProps {
    setShowActualTokens: (show: boolean) => void;
}

const ActualTokens: React.FC<ActualTokensProps> = ({ setShowActualTokens }) => {
    const [expandedRow, setExpandedRow] = useState<number | null>(null);

    const dispatch = useDispatch<AppDispatch>();
    const thirdPartyAuthTokenData = useSelector(ThirdPartyAuthTokenDataSelector);


    useEffect(() => {
        dispatch(getPatientThirdPartyAuthDataThunkAction({}));
    }, [dispatch]);

    const toggleRow = (id: number) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    const handleRevokeToken = (tokenId: number) => {
        dispatch(deleteThirdPartyAuthDataThunkAction({ action: "DEACTIVATE", idToken: tokenId }));
    };

    const renderExpandedInfo = (token: any) => (
        <div className="expanded-row">
            <div className="expanded-row__grid">
                <div className="expanded-row__item">
                    <span className="expanded-row__label">Created At:</span>
                    <span className="expanded-row__value">{token.createdAt.toLocaleString()}</span>
                </div>
                <div className="expanded-row__item">
                    <span className="expanded-row__label">Updated At:</span>
                    <span className="expanded-row__value">{token.updatedAt.toLocaleString()}</span>
                </div>
                <div className="expanded-row__item">
                    <span className="expanded-row__label">Patients:</span>
                    <span className="expanded-row__value">{token?.patients ? token?.patients.join(", ") : ""}</span>
                </div>
                <div className="expanded-row__item">
                    <span className="expanded-row__label">Access Token:</span>
                    <span className="expanded-row__value">{token.accessToken}</span>
                </div>
                <div className="expanded-row__item">
                    <button className="expanded-row__delete-button" onClick={() => handleRevokeToken(token.id)}>
                        Revoke access token
                        <img src={deleteIcon} alt="delete" width={20} />
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="actual-tokens">
            <div className="actual-tokens__content">
                <div className="actual-tokens__header">
                    <h1 className="actual-tokens__title">Actual Tokens</h1>
                    <img
                        src={closeIcon}
                        alt="close"
                        className="actual-tokens__close-icon"
                        onClick={() => setShowActualTokens(false)}
                    />
                </div>

                <div className="tokens-table">
                    {thirdPartyAuthTokenData.map(token => (
                        <div key={token.id}>
                            <div
                                className={`tokens-table__row ${expandedRow === token.id ? "expanded" : ""}`}
                                onClick={() => toggleRow(token.id)}
                            >
                                <div className="tokens-table__cell id">{token.id}</div>
                                <div className="tokens-table__cell token">{token.clientToken}</div>
                                <div className="tokens-table__cell scopes">{token.scopes.join(", ")}</div>
                                <div className="tokens-table__cell expires">{token.expiresAt.toLocaleString()}</div>
                                <div className={`tokens-table__cell status ${token.isActive ? "active" : "inactive"}`}>
                                    {token.isActive ? "Active" : "Inactive"}
                                </div>
                                <div className="tokens-table__cell-arrow">
                                    <span className={`arrow ${expandedRow === token.id ? "up" : "down"}`}>▼</span>
                                </div>
                            </div>
                            {expandedRow === token.id && renderExpandedInfo(token)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ActualTokens;

/**
 * body:{ action: "DEACTIVATE"} y el id del token en la url

17:06
url: /fhir/token/:idToken,
17:07
al cex-auth-rs
 */
