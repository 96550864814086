import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../api/auth.api";
import {
    AuthState,
    IPostForgotPassword,
    IPostPatientSignIn,
    IPostResetPassword,
    IPostSignIn,
    IPostSignUp,
} from "./auth.types";

import { AxiosResponse } from "axios";
import { CEThunkPayload } from "../../api/gateway/carexGateway";
import { ConsoleLogger } from "../../utils/logger";

const initialState: AuthState = {
    isPostSignUpLoading: false,
    isPostSignInLoading: false,
    isPostSignInCompleted: false,
    isPostResetPasswordLoading: false,
    isPostForgotPasswordLoading: false,
    examplePayload: {},
    loginResponse: {},
    preCreateAccountPayload: {},
    user: undefined,
    patient: {},
    isPatientSignInLoading: false,
    isPatientSignInCompleted: false,
    emrLayout: "layout1",
    isgetUserByIdLoading: false,
};
const logger = new ConsoleLogger();

const payloadFactory = (payload: CEThunkPayload<any>, service: Promise<any>) => {
    const success = payload.onSuccess ? payload.onSuccess : (res: AxiosResponse) => Promise.resolve(res);
    const error = payload.onError ? payload.onError : (err: AxiosResponse) => Promise.reject(err);
    logger.log("payloadFact Success", success);
    logger.log("payloadFact err", error);

    return service.then(success).catch(error);
};

export const postSignUpThunkAction = createAsyncThunk(
    "auth-app/auth/signUp",
    (payload: CEThunkPayload<IPostSignUp>) => {
        return payloadFactory(payload, AuthService.postSignUp(payload.data));
    }
);

export const postSignInThunkAction = createAsyncThunk(
    "auth-app/auth/signIn",
    (payload: CEThunkPayload<IPostSignIn>) => {
        logger.log("payloadSlice", payload);

        return payloadFactory(payload, AuthService.postSignIn(payload.data));
    }
);

export const postForgotPasswordThunkAction = createAsyncThunk(
    "auth-app/auth/forgotPassword",
    (payload: CEThunkPayload<IPostForgotPassword>) => {
        return payloadFactory(payload, AuthService.postForgotPassword(payload.data));
    }
);

export const postResetPasswordThunkAction = createAsyncThunk(
    "auth-app/auth/resetPassword",
    (payload: CEThunkPayload<IPostResetPassword>) => {
        return payloadFactory(payload, AuthService.postResetPassword(payload.data));
    }
);

export const preCreateAccountThunkAction = createAsyncThunk(
    "auth-app/auth/getPreCreateAccount",
    (payload: CEThunkPayload<any>) => {
        return payloadFactory(payload, AuthService.getPreCreateAccount(payload.data));
    }
);

export const postPreCreateAccountThunkAction: any = createAsyncThunk(
    "auth-app/auth/postPreCreateAccount",
    (payload: any) => {
        return payloadFactory(payload, AuthService.postPreCreateAccount(payload.data));
    }
);
//generate2FA
export const generate2FAThunkAction = createAsyncThunk("auth-app/auth/generate2FA", (payload: CEThunkPayload<any>) => {
    return payloadFactory(payload, AuthService.generate2FA(payload.data));
});
//verify2FA
export const verify2FAThunkAction = createAsyncThunk("auth-app/auth/verify2FA", (payload: CEThunkPayload<any>) => {
    return payloadFactory(payload, AuthService.verify2FA(payload.data));
});

export const getUserIdThunkAction = createAsyncThunk("auth/getUserId", (payload: CEThunkPayload<undefined>) => {
    return payloadFactory(payload, AuthService.getUserId());
});

export const postPatientSignInThunkAction = createAsyncThunk(
    "auth-app/auth/postPatientSignIn",
    (payload: CEThunkPayload<IPostPatientSignIn>) => {
        return payloadFactory(payload, AuthService.postPatientSignIn(payload.data));
    }
);

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        resetAuthState: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(postSignUpThunkAction.pending, (state: AuthState) => {
            state.isPostSignUpLoading = true;
        });
        builder.addCase(postSignUpThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostSignUpLoading = false;
            state.loginResponse = payload;
            logger.log("success response", payload);
        });
        builder.addCase(postSignUpThunkAction.rejected, (state: AuthState, {}) => {
            state.isPostSignUpLoading = false;
        });

        builder.addCase(postSignInThunkAction.pending, (state: AuthState) => {
            state.isPostSignInLoading = true;
            state.isPostSignInCompleted = false;
        });
        builder.addCase(postSignInThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostSignInCompleted = true;
            state.isPostSignInLoading = false;
            state.examplePayload = payload;
            const groupFlags = payload.flags && payload.flags.groupFlags ? payload.flags.groupFlags : null;
            const flags = payload.flags && payload.flags.userFlags ? payload.flags.userFlags : null;
            const disabledLeftNavItems = payload.disabledLeftNavItems;
            const userFlags = {
                ...groupFlags,
                ...flags,
            };
            logger.log("success response: ", payload);
            localStorage.setItem("Token", payload.jwtToken);
            localStorage.setItem("RefreshToken", payload.jwtRefreshToken);
            localStorage.setItem("missingAgreements", payload.missingAgreements);
            localStorage.setItem("userFlags", userFlags ? JSON.stringify(userFlags) : "");
            localStorage.setItem(
                "disabledLeftNavItems",
                disabledLeftNavItems ? JSON.stringify(disabledLeftNavItems) : JSON.stringify({})
            );
        });
        builder.addCase(postSignInThunkAction.rejected, (state: AuthState, {}) => {
            state.isPostSignInLoading = false;
        });

        builder.addCase(postPatientSignInThunkAction.pending, (state: AuthState) => {
            state.isPatientSignInLoading = true;
            state.isPatientSignInCompleted = false;
        });
        builder.addCase(postPatientSignInThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPatientSignInLoading = false;
            state.isPatientSignInCompleted = true;
            state.patient = payload;
            localStorage.setItem("Token", payload.token);
            logger.log("patient signing success response", payload);
        });
        builder.addCase(postPatientSignInThunkAction.rejected, (state: AuthState, {}) => {
            state.isPostSignInLoading = false;
        });

        builder.addCase(postForgotPasswordThunkAction.pending, (state: AuthState) => {
            state.isPostForgotPasswordLoading = true;
        });
        builder.addCase(postForgotPasswordThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostForgotPasswordLoading = false;
            state.examplePayload = payload;
            //logger.log("success response", payload);
        });
        builder.addCase(postForgotPasswordThunkAction.rejected, (state: AuthState) => {
            state.isPostForgotPasswordLoading = false;
        });

        builder.addCase(postResetPasswordThunkAction.pending, (state: AuthState) => {
            state.isPostResetPasswordLoading = true;
        });
        builder.addCase(postResetPasswordThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostResetPasswordLoading = false;
            state.examplePayload = payload;
            logger.log("success response", payload);
        });
        builder.addCase(postResetPasswordThunkAction.rejected, (state: AuthState) => {
            state.isPostResetPasswordLoading = false;
        });
        builder.addCase(preCreateAccountThunkAction.pending, (state: AuthState) => {
            state.isPostSignUpLoading = true;
            state.preCreateAccountPayload = {};
        });
        builder.addCase(preCreateAccountThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostSignUpLoading = false;
            state.preCreateAccountPayload = payload;
            logger.log("success response", payload);
        });
        builder.addCase(preCreateAccountThunkAction.rejected, (state: AuthState) => {
            state.isPostSignUpLoading = false;
        });
        builder.addCase(getUserIdThunkAction.pending, (state: AuthState) => {
            state.isgetUserByIdLoading = true;
        });
        builder.addCase(getUserIdThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isgetUserByIdLoading = false;
            state.user = payload;
            state.emrLayout = payload.emrLayout;
        });
        builder.addCase(getUserIdThunkAction.rejected, (state: AuthState) => {
            state.isgetUserByIdLoading = false;
            state.user = undefined;
        });
    },
});

export const { resetAuthState } = authSlice.actions;
export default authSlice.reducer;
