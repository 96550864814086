import { useState, useEffect } from "react";
import "./ThirdPartyAuth.styles.scss";

import ThirdPartyAuthLogin from "./Login/ThirdPartyAuthLogin";
import ThirdPartyAuthMain from "./MainContent/ThirdPartyAuthMain";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";

import { getUserIdThunkAction, } from "../../redux/auth/auth.slice";
import {  isPostSignIsCompletedSelector, getUserSelector, isgetUserByIdLoadingSelector, isPatientSignInCompletedSelector } from "../../redux/auth/auth.selectors";
import { isGettingTokenFromAuth0Selector } from "../../redux/thirdPArtyAuth/thirdPartyAuth.selectors";
import jwtDecode from "jwt-decode";

const ThirdPartyAuth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isPatient, setIsPatient] = useState(false);
    const [currentToken, setCurrentToken] = useState<string | null>(null);
    const isPostSignInCompleted = useSelector(isPostSignIsCompletedSelector)
    const isGettingTokenFromAuth0 = useSelector(isGettingTokenFromAuth0Selector)
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector(getUserSelector)
    const isgetUserByIdLoading = useSelector(isgetUserByIdLoadingSelector)
    const isPatientSignInCompleted = useSelector(isPatientSignInCompletedSelector)
    useEffect(() => {
        if (!isgetUserByIdLoading && user) {
            if(!isSignedIn){
                setIsSignedIn(true);
            }
            if ((user as any)?.tier === "patient") {
                setIsPatient(true);
            } else {
                setIsPatient(false);
            }
        }
    }, [user, isgetUserByIdLoading]);

    useEffect(() => {
        const checkAuth = async () => {
            if (currentToken) {
                const decodedToken: any = jwtDecode(currentToken);
                if (decodedToken.tier === "patient") {
                    setIsPatient(true)
                    setIsSignedIn(true)
                } else {
                    if (isPostSignInCompleted) {
                        setIsSignedIn(true)
                    } else {
                        dispatch(getUserIdThunkAction({ data: undefined }));
                    }
                }
            }
            setIsLoading(false);
        };

        checkAuth();
    }, [currentToken]);

    useEffect(() => {
    setCurrentToken(localStorage.getItem("Token"));
},[ isPostSignInCompleted, isGettingTokenFromAuth0, isPatientSignInCompleted])
    if (isLoading) {
        return (
            <div className="loading-container">
                <span className="loaderAuth"></span>
            </div>
        );
    }

    return (
        <>
            {!isSignedIn && <ThirdPartyAuthLogin setIsSignedIn={setIsSignedIn} isPatient={isPatient} setIsPatient={setIsPatient} />}
            {isSignedIn &&(
                <ThirdPartyAuthMain isPatient={isPatient} />
            )}
        </>
    );
};

export default ThirdPartyAuth;
