
import React from 'react';
import "./footerstyles.sass";

const Footer: React.FC = () => {
    return (
        <div className="footerContainer">
            <div className="footerContent">
                <p className="footerText">
                    14555 Dallas Pkwy, STE 100-275, Dallas TX 75254
                </p>
                <p className="footerText">
                    Careexpand 2024. All rights reserved.
                </p>
            </div>
            <div className="footerContent">    
                <p className="footerText footerVersion">
                    Product Version - v2024.11.0
                </p>
            </div>
        </div>
    );
};

export default Footer;