import React from "react";
import TitleLabel from "../../components/common/TitleLabel/TitleLabel";
import SubtitleLabel from "../../components/common/SubtitleLabel/SubtitleLabel";

import "./AccountVerified.styles.sass";

const AccountVerified = () => {
    return (
        <div className="loginFormContainer">
            <div className="formContainer">
                <TitleLabel label={"Account verified"} />
                <SubtitleLabel label={"Your account has been verified successfully"} />
                <button
                    className="reset_blueButton"
                    style={{
                        fontSize:
                            window.innerWidth <= 320
                                ? "11px"
                                : window.innerWidth === 768
                                    ? "10px"
                                    : window.innerWidth >= 426 && window.innerWidth <= 514
                                        ? "10px"
                                        : "14px",
                    }}
                >
                    Continue to login
                </button>
            </div>
        </div>
    );
};

export default AccountVerified;
