import { useLocation } from "react-router-dom";
const Base = () => {
    const location = useLocation();

    if (location.pathname === "/" && location.hash === "")
        window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3015"}/#/login`;

    return <></>;
};

export default Base;
