import React, { useEffect, useState } from "react";
import "./ConnectWithCareExpand.styles.scss";
import cexLogoSm from "../../../assets/icons/cex-logo-sm.svg";

const ConnectWithCareExpand = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <div className={`connect-modal ${isVisible ? "connect-modal--visible" : "connect-modal--hidden"}`}>
            <div className="connect-modal__container">
                <h1 className="connect-modal__title">
                    <span className="connect-modal__title-logo">
                        <img src={cexLogoSm} alt="Careexpand" width={20} />
                    </span>{" "}
                    Connect with Careexpand
                </h1>
                <div className="connect-modal__content">
                    <p className="connect-modal__text">
                        If you want to connect with <b>Careexpand</b>, please send us an email to{" "}
                        <a href="mailto:support@careexpand.com" className="connect-modal__link">
                            support@careexpand.com
                        </a>{" "}
                        and we will guide you through the process.
                    </p>
                    <div className="connect-modal__buttons">
                        <a href="mailto:support@careexpand.com" className="connect-modal__email-link">
                            <button className="connect-modal__button connect-modal__button--primary">
                                Let's connect
                            </button>
                        </a>
                        <button
                            className="connect-modal__button connect-modal__button--secondary"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectWithCareExpand;
