import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import "./ForgotPasswordForm.styles.sass";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postForgotPasswordThunkAction } from "../../../redux/auth/auth.slice";
import { useAppDispatch } from "../../../redux/hooks";
import { Modal, CEXModal } from "@careexpand/cex-core-components/esm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";

const ForgotPasswordForm = ({}: any) => {
    const [t]: any = useTranslation("global");
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>("");
    const [otp, setOTP] = useState<any>();
    const [error, setError] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [modalAction, setModalAction] = useState<any>(null);
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft]);

    const sendOTP = (email: string) => {
        // Validación básica de formato de email usando una expresión regular
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            setModalAction("INVALID_EMAIL");
            return;
        }

        if (!emailRegex.test(email)) {
            setModalAction("INVALID_EMAIL");
            return;
        }

        setEmail(email);
        const url = process.env.REACT_APP_LEGACY_API_URL + "/requestOTP";
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: { email } }),
        })
            .then((res: any) => {
                if (!res.ok) {
                    setModalAction("FAILED_EMAIL");
                    return;
                } else {
                    setTimeLeft(120);
                    setModalAction("ENTER_OTP");
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setModalAction("FAILED_EMAIL");
            });
    };

    const renderPopupActions = () => {
        let modalType = "info";
        let title = "info";
        let subtitle = "info";
        switch (modalAction) {
            case "INVALID_EMAIL":
                modalType = "error";
                title = "Invalid Email";
                subtitle = "Please enter a valid email address";
                break;
            case "INVALID_DATA":
                modalType = "error";
                title = "Invalid data";
                subtitle = "Please check information entered";
                break;
            case "FAILED_EMAIL":
                modalType = "error";
                title = "Failed";
                subtitle = "Failed to send email, Try again later";
                break;
            case "EMAIL_SENT":
                modalType = "info";
                title = "Email sent successfully";
                subtitle = "Please check your inbox to reset your password";
                break;
        }
        return <CEXModal type={modalType} handleModal={() => setModalAction(null)} title={title} subtitle={subtitle} />;
    };

    const handleOTPLogin = () => {
        if (!otp || otp.length < 6) {
            setError({ otp: "Enter valid OTP" });
            return;
        }
        const data: any = {
            email: email,
            otp: otp,
            isOTPLogin: true,
            timezone: "2022-12-27T18:02:14.894Z",
        };
        const url = process.env.REACT_APP_LEGACY_API_URL + "/signIn";
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
        })
            .then((res: any) => {
                if (!res.ok) return Promise.reject(res);
                res.json().then((resData: any) => {
                    const token = resData.token;
                    localStorage.setItem("Token", token);
                    window.location.assign("/home#/home");
                });
            })
            .catch(() => {
                setError({ otp: "Invalid OTP or failed to validate OTP, Try again" });
            });
    };

    const renderModal = () => {
        return (
            <Modal
                title={"Please Enter OTP"}
                titleDescription={"Please enter the OTP that is sent by Email / Phone"}
                closeModal={() => {
                    setModalAction(null);
                }}
                buttons={[
                    {
                        onClick: handleOTPLogin,
                        displayText: "Login",
                        theme: { primaryColor: "#009EFC", textColor: "#FFF" },
                    },
                ]}
            >
                <div key={1}>
                    <div key={2} className="otp__input">
                        <input type="text" value={otp} onChange={(e: any) => setOTP(e.target.value)} />
                        {error && error.otp && <label className="input__error">{error.otp}</label>}
                    </div>
                    {timeLeft > 0 ? (
                        <div key={3} className="cex-modal__subtitle">
                            Time left:
                            {` ${Math.floor(timeLeft / 60)}:${Number(timeLeft % 60)
                                .toString()
                                .padStart(2, "0")} `}
                            seconds
                        </div>
                    ) : (
                        <div key={4} className="cex-modal__subtitle">
                            <span
                                style={{ cursor: "pointer", color: "#489dff", fontSize: "15px" }}
                                onClick={() => {
                                    sendOTP(email);
                                }}
                            >
                                Resend OTP
                            </span>
                        </div>
                    )}
                </div>
            </Modal>
        );
    };

    return (
        <div className="userDetailsForm">
            {modalAction && modalAction !== "ENTER_OTP" && renderPopupActions()}
            {modalAction && modalAction === "ENTER_OTP" && renderModal()}
            <Formik
                validateOnChange
                validateOnBlur
                initialValues={{ email: "" }}
                onSubmit={() => {
                    return;
                }}
                validationSchema={yup.object().shape({
                    email: yup.string().email("Please enter a valid email address"),
                })}
            >
                {({ errors, touched, submitCount, values, validateForm, resetForm }) => (
                    <Form className="userDetailsForm">
                        <div className="inputFieldDiv">
                            <div className="pi-input-skel-block">
                                <div className="pi-input">
                                    <label className="pi-label" htmlFor="">
                                        Type your email below
                                    </label>
                                    <div className="pi-input-skel">
                                        <span className="pi-input-prefix" style={{ padding: "8px 8px" }}>
                                            <FontAwesomeIcon icon={faEnvelope} size="2xl" style={{ width: "17px" }} />
                                        </span>
                                        <Field name="email" type="email" placeholder="Email" className="pi-input-ele" />
                                    </div>
                                </div>
                                <label className="input__error">
                                    {errors.email && (touched.email || submitCount > 0) && errors.email}
                                </label>
                            </div>
                            <div>
                                <button
                                    className="forgot-commonText"
                                    style={{
                                        float: "left",
                                        cursor: "pointer",
                                        background: "transparent",
                                        border: "none",
                                        marginTop: "13px",
                                        color: "#489DFF",
                                    }}
                                    type="button"
                                    onClick={() => {
                                        sendOTP(values.email);
                                    }}
                                >
                                    {t("forgot-password.send-otp")}
                                </button>
                            </div>
                            <div className="link-button-container">
                                <Link
                                    to="/login"
                                    className="forgot-goToRegisterLink"
                                    style={{ textDecoration: "none" }}
                                >
                                    {t("forgot-password.login-link")}
                                </Link>
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="BlueButton"
                                        onClick={() => {
                                            validateForm();
                                            if (values.email && Object.keys(errors).length === 0) {
                                                setIsLoading(true);
                                                dispatch(
                                                    postForgotPasswordThunkAction({
                                                        data: {
                                                            email: values.email,
                                                            phone: values.email,
                                                        },
                                                    })
                                                ).finally(() => {
                                                    setIsLoading(false);
                                                    resetForm();
                                                    setModalAction("EMAIL_SENT");
                                                });
                                            }
                                        }}
                                        style={{
                                            fontSize:
                                                window.innerWidth <= 320
                                                    ? "11px"
                                                    : window.innerWidth === 768
                                                    ? "10px"
                                                    : window.innerWidth >= 426 && window.innerWidth <= 514
                                                    ? "10px"
                                                    : "14px",
                                        }}
                                    >
                                        {t("forgot-password.send-password-button")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPasswordForm;
