import React from "react";
//import { useTranslation } from "react-i18next";
import Footer from "../../components/common/Footer/footer";
import "./TermsAndConditions.styles.sass";
import { TERMS_AND_CONDITIONS } from "../../constants/termsAndConditions";

const TermsAndConditions = () => {
    return (
        <div className="termsContainer">
            <div className="contentContainer">
                <div className="termsContent">
                    <h1 className="terms-title">Terms and Conditions</h1>
                    <p className="lastUpdated">{TERMS_AND_CONDITIONS.metadata.lastUpdated}</p>
                    <div className="terms-text">
                        {TERMS_AND_CONDITIONS.sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <h2>{section.title}</h2>
                                {section.content.split('\n').map((line, lineIndex) => (
                                    <React.Fragment key={`${index}-${lineIndex}`}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TermsAndConditions; 