import { Button, Input, InputArea } from "@careexpand/cex-core-components/esm";
import { createReviewThunkAction } from "../../redux/posts/posts.slice";
import carexLogo from "../../assets/images/Careexpand-Logo-no-padding.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import moment from "moment";
import { useState } from "react";
import "./PatientSurvey.styles.sass";
import Rating from "./Rating/Rating";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

const PatientSurvey = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchParams] = useSearchParams();
    const idUser = searchParams.get("idUser");
    const idPatient = searchParams.get("idPatient");
    const email = searchParams.get("email");

    const [error, setError] = useState<any>({});
    const [payload, setPayload] = useState<any>({
        idPatient: parseInt(idPatient || "0"),
        email: email || "",
        rating: 0,
        source: "survey.careexpand.com",
        review_date: new Date(),
        review_text: "",
        idUser: parseInt(idUser || "0")
    });

    const validateForm = () => {
        let proceed = true;
        const errorObj:any = {};
        const emailRegExp = /\S+@\S+\.\S+/
        if (!payload.rating || payload.rating <= 0) {
            errorObj.rating = "Atleast one rating is required";
            proceed = false;
        }
        if (!payload.review_text || payload.review_text === "") {
            errorObj.review_text = "Please enter comments";
            proceed = false;
        }
        if (!payload.email || payload.email === "" || emailRegExp.test(payload.email) === false) {
            errorObj.email = "Please enter a valid email";
            proceed = false;
        }
        setError(errorObj);
        return proceed;
    };

    const handleSendReview = () => {
        if(validateForm()) {
            dispatch(
                createReviewThunkAction({
                    data: {
                        ...payload,
                    },
                })
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire("Success", "Review submitted successfully, . Thanks for supporting careexpand", "success");
                    setPayload({
                        ...payload,
                        review_text: "",
                        rating: 0,
                        email: ""
                    });
                } else {
                    Swal.fire("Failed", "Your review couldn't be created at the moment.Please, try again later", "error");
                }
            });
        }
    };

    return (
        <div className="patientSurveyContainer">
            <div className="carexLogoContainer">
                <img className="carexLogo" src={carexLogo} alt="Careexpand_logo" />
                <div className="carexLogoText">The Operating System for Virtual Medicine</div>
            </div>
            <div className="patientSurveyHeader">
                <div className="patientSurveyTitle">
                    <div className="patientSurveyHeaderContent">
                        <div className="patientSurveyHeaderText">Satisfaction survey</div>
                        <div className="patientSurveyHeaderQuestions">3 questions</div>
                    </div>
                    <div className="patientSurveyHeaderDate">{moment().format("MMM DD, YYYY")}</div>
                </div>
            </div>
            <div className="patientSurveyQuestions">
                <div className="patientSurveyQuestionContainer">
                    <div className="patientSurveyQuestion">
                        <div className="patientSurveryQuestionNumber">1</div>
                    </div>
                    <div className="patientSurveyQuestionAnswer">
                        <div className="patientSurveyQuestionText">How good was the provided doctors attention?</div>
                        <Rating rating={payload.rating} setRating={(value:any)=>{
                            setPayload({
                                ...payload,
                                rating: value
                            });
                        }} />
                        {error && error.rating && (
                            <span className="error-msg">{error.rating}</span>
                        )}
                    </div>
                </div>
                <div className="patientSurveyQuestionContainer">
                    <div className="patientSurveyQuestion">
                        <div className="patientSurveryQuestionNumber">2</div>
                    </div>
                    <div className="patientSurveyQuestionAnswer">
                        <div className="patientSurveyQuestionText">Would you like to provide any suggestion?</div>
                        <InputArea
                            setValue={(value: any) => {
                                setPayload({
                                    ...payload,
                                    review_text: value
                                });
                            }}
                            placeholder={'Write your comments...'}
                            value={payload.review_text}
                        />
                        {error && error.review_text && (
                            <span className="error-msg">{error.review_text}</span>
                        )}
                    </div>
                </div>
                <div className="patientSurveyQuestionContainer">
                    <div className="patientSurveyQuestion">
                        <div className="patientSurveryQuestionNumber">3</div>
                    </div>
                    <div className="patientSurveyQuestionAnswer">
                        <div className="patientSurveyQuestionText">Please, enter your email:</div>
                        <Input
                            setValue={(value: any) => {
                                setPayload({
                                    ...payload,
                                    email: value
                                });
                            }}
                            placeholder={"E-mail address"}
                            value={payload.email}
                        />
                        {error && error.email && (
                            <span className="error-msg">{error.email}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="patientSurveyButtons">
                <Button
                    action={"button"}
                    onClick={() => {
                        setPayload({
                            ...payload,
                            review_text: "",
                            rating: 0,
                            email: ""
                        });
                    }}
                    children={"Reset"}
                    theme={{ primaryColor: "#FF5c5c", textColor: "#fff" }}
                />
                <Button
                    action={"button"}
                    onClick={() => {
                        handleSendReview();
                    }}
                    children={"Confirm"}
                    theme={{ primaryColor: "#489dff", textColor: "#fff" }}
                />
            </div>
        </div>
    );
};

export default PatientSurvey;
