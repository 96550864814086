import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CEThunkPayload } from "../../api/gateway/carexGateway";
import { AxiosResponse } from "axios";
import { IPostsState } from "./posts.types";
import postsFeedService from "../../api/Posts.api";

const initialState: IPostsState = {
    postsData: {},
    isLoadingPosts: false,
    createPostResult: {},
    isCreatingPost: false,
    updatePostResult: "",
    isUpdatingPost: false,
    generatedAIText: {
        content: "",
        socialMediaPlatforms: { facebookText: "", linkedInText: "", twitterText: "", hashtags: [], links: [] },
        rawData: "",
    },
    isGeneratingAIText: false,
    isLoadingPostAccounts: false,
    postAccounts: { totalAccounts: 0, accountsData: [] },
    isLoadingCreatePostAccount: false,
    createPostAccount: {},
    isLoadingUpdatePostAccount: false,
    updatePostAccount: {},
};
const payloadFactory = (payload: CEThunkPayload<any>, service: Promise<any>) => {
    const success = payload.onSuccess ? payload.onSuccess : (res: AxiosResponse) => Promise.resolve(res);
    const error = payload.onError ? payload.onError : (err: AxiosResponse) => Promise.reject(err);
    return service.then(success).catch(error);
};
export const createPostThunkAction = createAsyncThunk("posts/createPost", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, postsFeedService.addNewPost(payload.data))
);
export const createReviewThunkAction = createAsyncThunk("posts/createReview", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, postsFeedService.addNewReview(payload.data))
);
export const getAllPostsThunkAction = createAsyncThunk("posts/getPosts", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, postsFeedService.getAllPosts(payload.data))
);
export const updatePostThunkAction = createAsyncThunk("posts/updatePost", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, postsFeedService.updatePost(payload.data.id, payload.data))
);

export const getAIPostDataThunkAction = createAsyncThunk("posts/getAIPosts", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, postsFeedService.getAIPostData(payload.data))
);

export const createPostAccountThunkAction = createAsyncThunk(
    "posts/createPostAccount",
    (payload: CEThunkPayload<any>) => payloadFactory(payload, postsFeedService.createPostAccount(payload.data))
);
export const updatePostAccountThunkAction = createAsyncThunk(
    "posts/updatePostAccount",
    (payload: CEThunkPayload<any>) => payloadFactory(payload, postsFeedService.updatePostAccount(payload.data))
);
export const getPostAccountsThunkAccount = createAsyncThunk("posts/getPostAccounts", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, postsFeedService.getAllPostAccounts(payload.data))
);
const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {
        addNewPost: (state: any, action: any) => {
            state.posts.push(action.payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(createPostThunkAction.pending, (state: IPostsState) => {
            state.isCreatingPost = true;
            state.createPostResult = {};
        });
        builder.addCase(createPostThunkAction.fulfilled, (state: IPostsState, { payload }) => {
            state.isCreatingPost = false;
            state.createPostResult = { ...payload };
        });
        builder.addCase(createPostThunkAction.rejected, (state: IPostsState) => {
            state.isCreatingPost = false;
        });
        builder.addCase(getAllPostsThunkAction.pending, (state: IPostsState) => {
            state.isLoadingPosts = true;
            state.createPostResult = {};
        });
        builder.addCase(getAllPostsThunkAction.fulfilled, (state: IPostsState, { payload }) => {
            state.isLoadingPosts = false;
            state.postsData = { ...payload };
        });
        builder.addCase(getAllPostsThunkAction.rejected, (state: IPostsState) => {
            state.isLoadingPosts = false;
        });
        builder.addCase(updatePostThunkAction.pending, (state: IPostsState) => {
            state.isUpdatingPost = true;
            state.updatePostResult = "";
        });
        builder.addCase(updatePostThunkAction.fulfilled, (state: IPostsState, { payload }) => {
            state.isUpdatingPost = false;
            state.updatePostResult = payload;
        });
        builder.addCase(updatePostThunkAction.rejected, (state: IPostsState) => {
            state.isUpdatingPost = false;
        });

        builder.addCase(getAIPostDataThunkAction.pending, (state: IPostsState) => {
            state.isGeneratingAIText = true;
            state.generatedAIText = {
                content: "",
                socialMediaPlatforms: { facebookText: "", linkedInText: "", twitterText: "", hashtags: [], links: [] },
                rawData: "",
            };
        });
        builder.addCase(getAIPostDataThunkAction.fulfilled, (state: IPostsState, { payload }) => {
            state.isGeneratingAIText = false;
            //state.generatedAIText = { ...payload };//TODO: change the state assignation to this payload

            const basic = {
                content: "",
                socialMediaPlatforms: {
                    facebookText: "this is the mocked up text for facebook after selector has been updated ",
                    linkedInText: "thiss is the mocked up text for linkedIn after selector has been updated ",
                    twitterText: "thisss is the mocked up text for facebook after selector has been updated ",
                    hashtags: ["myTag", "yourTag", "his/hers tagsssss"],
                    links: ["www.googlw.com"],
                },
                rawData: {},
            };
            state.generatedAIText = {
                ...basic,
                ...payload,
                socialMediaPlatforms: { ...basic.socialMediaPlatforms, ...payload.socialMediaPlatforms },
            };
            /*content:"",
                socialMediaPlatforms:{
                facebookText: "this is the mocked up text for facebook after selector has been updated ",
                linkedInText: "thiss is the mocked up text for linkedIn after selector has been updated ",
                twitterText: "thisss is the mocked up text for facebook after selector has been updated ",
                hashtags: ["myTag", "yourTag", "his/hers tagsssss"],
                links: ["www.googlw.com"],
                },
                rawData:{}
            };*/
        });
        builder.addCase(getAIPostDataThunkAction.rejected, (state: IPostsState) => {
            state.isGeneratingAIText = false;
        });

        builder.addCase(getPostAccountsThunkAccount.pending, (state: IPostsState) => {
            state.isLoadingPostAccounts = true;
            state.postAccounts = { totalAccounts: 0, accountsData: [] };
        });
        builder.addCase(getPostAccountsThunkAccount.fulfilled, (state: IPostsState, { payload }) => {
            state.isLoadingPostAccounts = false;
            state.postAccounts = { ...payload };
        });
        builder.addCase(getPostAccountsThunkAccount.rejected, (state: IPostsState) => {
            state.isLoadingPostAccounts = false;
        });

        builder.addCase(createPostAccountThunkAction.pending, (state: IPostsState) => {
            state.isLoadingCreatePostAccount = true;
            state.createPostAccount = {};
        });
        builder.addCase(createPostAccountThunkAction.fulfilled, (state: IPostsState, { payload }) => {
            state.isLoadingCreatePostAccount = false;
            state.createPostAccount = { ...payload };
        });
        builder.addCase(createPostAccountThunkAction.rejected, (state: IPostsState) => {
            state.isLoadingCreatePostAccount = false;
        });
        builder.addCase(updatePostAccountThunkAction.pending, (state: IPostsState) => {
            state.isLoadingUpdatePostAccount = true;
            state.updatePostAccount = {};
        });
        builder.addCase(updatePostAccountThunkAction.fulfilled, (state: IPostsState, { payload }) => {
            state.isLoadingUpdatePostAccount = false;
            state.updatePostAccount = { ...payload };
        });
        builder.addCase(updatePostAccountThunkAction.rejected, (state: IPostsState) => {
            state.isLoadingUpdatePostAccount = false;
        });
    },
});

export const { addNewPost } = postsSlice.actions;
export default postsSlice.reducer;
