import React from "react";
import TitleLabel from "../../components/common/TitleLabel/TitleLabel";
import SubtitleLabel from "../../components/common/SubtitleLabel/SubtitleLabel";
import VerifyAccountForm from "./modules/VerifyAccountForm";
import "./VerifyAccount.styles.sass";

const VerifyAccount = () => {
    return (
        <div className="loginFormContainer">
            <div className="formContainer">
                <TitleLabel label={"Create a new password"} />
                <SubtitleLabel label={"Please, create a new password for your account"} />
                <VerifyAccountForm />
            </div>
        </div>
    );
};

export default VerifyAccount;
